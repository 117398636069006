import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import {
  ReceivingInspectItem,
  ReceivingItem,
} from "@sellernote/_shared/src/types/fulfillment/receiving";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import {
  checkUsesManagementDate,
  convertItemListToMapBySKU,
  getLabelCombinedWithSKUIdAndManagementDate,
  getManagementDateLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkForNormalItemAsInspection } from "@sellernote/_shared/src/utils/fulfillment/inspection";
import {
  checkConfirmedAsSingleLocationInspection,
  getHasMultiLocationInspection,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";
import Toast from "@sellernote/_sds-v2/src/components/Toast";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useCompleteInspectionByForce from "hooks/receiving/useCompleteInspectionByForce";
import useRemoveInspectionPersonInCharge from "hooks/receiving/useRemoveInspectionPersonInCharge";
import useResetInspectionOfItem from "hooks/receiving/useResetInspectionOfItem";
import {
  getCounterKeyForMultiLocationInspection,
  getCounterKeyForSingleLocationInspection,
  SKUCountingForInspection,
} from "hooks/receiving/useSKUCountingForInspection";
import useSKUImageInfoModal from "hooks/sku/useSKUImageInfoModal";
import {
  getCanReset,
  getCurrentSKUCount,
  getHasRightToReset,
  getPersonInCharge,
} from "./utils";

import CompleteByForce from "components/CompleteByForce";
import CountForScanning from "components/CountForScanning";
import PersonInCharge from "components/PersonInCharge";
import ProblemInput from "components/ProblemInput";
import ResetCounting from "components/ResetCounting";
import SelectMultiLocation from "components/SelectMultiLocation";
import SKUInfo from "components/SKUInfo";

import Styled from "./index.styles";

export interface ReceivingInspectionDetailTableItem {
  SKUId: React.ReactNode;
  count: React.ReactNode;
  personInCharge?: React.ReactNode;
  status: "Y" | "N" | "";
  managementDate: React.ReactNode;
  reset: React.ReactNode;
  completeByForce: React.ReactNode;
  selectMultiLocation: React.ReactNode;
  // printBarcode: React.ReactNode;
  problem: ReactNode;
  /** subRow의 경우 radio버튼으로 선택가능 */
  selection: ReactNode;
}

function SKUList({
  receivingId,
  receivingItems,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  skuCounting,
  resetAfterRemoveInProgressPersonInCharge,
  resetCountByCounterKey,
  setConfirmModal,
  itemIdInProgress,
  setItemIdInProgress,
}: {
  receivingId: number;
  receivingItems: ReceivingItem[] | undefined;
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight | undefined) => void;
  skuCounting: SKUCountingForInspection;
  resetAfterRemoveInProgressPersonInCharge: (counterKey: string) => () => void;
  resetCountByCounterKey: (counterKey: string) => void;
  setConfirmModal: React.Dispatch<
    React.SetStateAction<ConfirmModal | undefined>
  >;
  itemIdInProgress: number;
  setItemIdInProgress: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [toastMessage, setToastMessage] = useState("");

  const openInspectionCompleteToast = () => {
    setToastMessage("분할검수 완료 후 입력 가능합니다.");
  };
  const closeInspectionCompleteToast = () => {
    setToastMessage("");
  };

  const history = useHistory();

  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const isCurrentManagerReceivingManager = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_RECEIVING_MANAGER
  );

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const {
    fetchImageInfoBySKUId,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
  } = useSKUImageInfoModal();

  const getWorkerById = useGetWorkerByIdFactory();

  const { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge } =
    useRemoveInspectionPersonInCharge();

  const {
    resetInspectionOfItem,
    ResponseHandlerOfResetInspectionOfItemExceptInspector,
  } = useResetInspectionOfItem();

  const {
    completeInspectionByForce,
    ResponseHandlerOfCompletingInspectionByForce,
  } = useCompleteInspectionByForce();

  const handleCountAddByDirectInput = useCallback(
    (key: string) => (count: number | undefined) => {
      if (!count) return;

      const target = skuCounting.counter.counterInfo[key];

      skuCounting.counter.addCountByInput({ id: key, count });

      setRowInfoToHighlight({
        rowKey: target.itemId,
      });
    },
    [setRowInfoToHighlight, skuCounting]
  );

  const resetLocalCount = useCallback(
    ({
        itemId,
        hasMultiLocation,
        inspectItems,
        skuId,
      }: {
        itemId: number;
        hasMultiLocation: boolean;
        inspectItems: ReceivingInspectItem[];
        skuId: number;
      }) =>
      () => {
        const counterKeyListToReset = inspectItems.map((inspectItem) =>
          getCounterKeyForMultiLocationInspection({
            skuId,
            inspectingId: inspectItem.inspectingId,
          })
        );

        if (hasMultiLocation) {
          counterKeyListToReset.forEach((counterKey) =>
            resetCountByCounterKey(counterKey)
          );
        } else {
          resetCountByCounterKey(
            getCounterKeyForSingleLocationInspection({
              skuId,
              itemId,
            })
          );
        }
      },
    [resetCountByCounterKey]
  );

  /**
   * rowType에 맞는 row를 생성한다.
   */
  const createRow = useCallback(
    ({
      rowType,
      receivingItem,
      hasMultiLocation,
      currentCount,
      goalCount,
    }: {
      rowType: "basicRow" | "mainRow" | "subRow";
      receivingItem: ReceivingItem;
      hasMultiLocation: boolean;
      currentCount: number;
      goalCount: number;
    }): TableDataListItem<ReceivingInspectionDetailTableItem> => {
      const currentManagerId = currentManager?.id;

      const counterKey = getCounterKeyForSingleLocationInspection({
        skuId: receivingItem.sku.id,
        itemId: receivingItem.id,
      });

      const skuId = getFormattedSingleSkuId(receivingItem.sku.id);

      const currentUserIsSingleLocationInspectionAssignee =
        !hasMultiLocation &&
        receivingItem.inspectItems[0]?.inspectorId === currentManagerId;
      const completedSingleLocationInspection =
        !hasMultiLocation &&
        receivingItem.inspectItems[0]?.isCompleteInspecting;
      const skuInProgressInSingleLocationInspection =
        !hasMultiLocation &&
        receivingItem.inspectItems[0]?.inspectingId ===
          skuCounting.skuInProgress?.inspectingId;
      const canOpenDirectInputModal =
        currentUserIsSingleLocationInspectionAssignee &&
        !completedSingleLocationInspection &&
        skuInProgressInSingleLocationInspection;

      const completedInspection = receivingItem.inspectItems.every(
        (ii) => ii.isCompleteInspecting
      );

      const hasRightToReset = getHasRightToReset({
        hasMultiLocation,
        currentManagerId,
        inspectItems: receivingItem.inspectItems,
      });
      const canReset = getCanReset({
        skuId: receivingItem.sku.id,
        counterData: skuCounting.counter.counterInfo,
        hasRightToReset,
        hasMultiLocation,
        itemId: receivingItem.id,
      });

      const problemCount = receivingItem.inspectProblems.reduce((acc, cur) => {
        return acc + cur.quantity;
      }, 0);

      const canCompleteInspectionByForce =
        !completedInspection &&
        isCurrentManagerReceivingManager &&
        !currentCount;

      const confirmedAsSingleLocationInspection =
        checkConfirmedAsSingleLocationInspection(receivingItem);
      const canSelectMultiLocation =
        !confirmedAsSingleLocationInspection && !problemCount;

      const skuInProgress =
        receivingItem.id === skuCounting.skuInProgress?.itemId;

      const selection = (() => {
        if (rowType !== "subRow") return;

        const isSelectedItem = receivingItem.id === itemIdInProgress;

        const isCompletedInspecting = receivingItem.inspectItems.every(
          (inspectItem) => inspectItem.isCompleteInspecting
        );

        const isDisabledItem = isCompletedInspecting || hasMultiLocation;

        if (isDisabledItem) {
          return (
            <Icon
              type="checkStrokeCircleDisabled"
              size={1}
              color={COLOR.grayScale_300}
            />
          );
        }

        if (isSelectedItem) {
          return (
            <Icon
              type="radioSelected"
              size={1}
              color={COLOR.primaryBlue}
              onClick={() => setItemIdInProgress(0)}
            />
          );
        }

        return (
          <Icon
            type="radio"
            size={1}
            color={COLOR.grayScale_700}
            onClick={() => setItemIdInProgress(receivingItem.id)}
          />
        );

        return <></>;
      })();

      const SKUId = (
        <SKUInfo
          skuId={receivingItem.sku.id}
          skuBarcode={receivingItem.sku.barCode}
          handleSKUIdClick={fetchImageInfoBySKUId}
        />
      );

      const rowKey =
        rowType === "mainRow"
          ? `main-row-${receivingItem.skuId}`
          : receivingItem.id;

      const count = (() => {
        if (rowType === "mainRow") {
          // mainRow는 총계 카운트만 표시
          return getStyledSKUCount({
            currentCount,
            goalCount,
          });
        }

        return (
          <CountForScanning
            // type="inspection"
            canOpenDirectInputModal={canOpenDirectInputModal}
            // skuId={receivingItem.sku.id}
            currentQty={currentCount}
            quantity={goalCount}
            // onCountAdd={handleCountAddByDirectInput(counterKey)}
            openDirectInputModal={() => {
              handleDirectInputModalOpen();
              setCountForScanning({
                type: "receivingInspection",
                skuId: receivingItem.sku.id,
                currentQty: currentCount,
                quantity: receivingItem.quantity,
                onCountAdd: handleCountAddByDirectInput(counterKey),
                managementKind: receivingItem.sku.managementKind,
                managementDate: receivingItem.managementDate,
              });
            }}
          />
        );
      })();

      const problem =
        rowType === "mainRow" ? (
          ""
        ) : (
          <ProblemInput
            type="receiving"
            itemId={receivingItem.id}
            skuId={receivingItem.sku.id}
            inspectionCount={currentCount}
            problemCount={problemCount}
            managementKind={receivingItem.sku.managementKind}
            managementDate={receivingItem.managementDate}
            disabled={
              hasMultiLocation ? false : completedInspection || !skuInProgress
            }
            hasMultiLocation={hasMultiLocation}
            openInspectionCompleteToast={openInspectionCompleteToast}
            isCompletedInspection={completedInspection}
          />
        );

      const managementDate = (() => {
        const usesManagementDate = checkUsesManagementDate({
          managementKind: receivingItem.sku.managementKind,
          managementDate: receivingItem.managementDate,
        });

        if (!usesManagementDate) return "-";

        if (rowType === "basicRow") {
          return (
            <Styled.basicRowOfManagementDateContainer>
              {receivingItem.sku.managementKind && (
                <span>
                  {
                    INVENTORY_MANAGEMENT_KIND_MAP[
                      receivingItem.sku.managementKind
                    ]
                  }
                </span>
              )}
              <span>
                {toFormattedDate(receivingItem.managementDate, "YYYY-MM-DD")}
              </span>
            </Styled.basicRowOfManagementDateContainer>
          );
        }

        if (rowType === "mainRow") {
          return receivingItem.sku.managementKind
            ? INVENTORY_MANAGEMENT_KIND_MAP[receivingItem.sku.managementKind]
            : "";
        }

        if (rowType === "subRow") {
          return toFormattedDate(receivingItem.managementDate, "YYYY-MM-DD");
        }

        return "";
      })();

      const completeByForce =
        rowType === "mainRow" ? (
          ""
        ) : (
          <CompleteByForce
            skuId={receivingItem.sku.id}
            canCompleteByForce={canCompleteInspectionByForce}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: `강제로 완료처리하시겠습니까? (${getLabelCombinedWithSKUIdAndManagementDate(
                  {
                    formattedSKUId: skuId,
                    managementKind: receivingItem.sku.managementKind,
                    managementDate: receivingItem.managementDate,
                  }
                )})`,
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: completeInspectionByForce({
                      receivingId,
                      itemId: receivingItem.id,
                      setConfirmModal,
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // completeByForce={completeInspectionByForce({
            //   receivingId: receivingId,
            //   skuId: receivingItem.sku.id,
            // })}
          />
        );

      const selectMultiLocation =
        rowType === "mainRow" ? (
          ""
        ) : (
          <SelectMultiLocation
            type="inspection"
            canSelectMultiLocation={canSelectMultiLocation}
            skuId={receivingItem.sku.id}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "content",
                title: (
                  <>
                    {skuId}(SKU ID)
                    {receivingItem.managementDate && (
                      <>
                        <br />
                        {getManagementDateLabel(
                          receivingItem.sku.managementKind,
                          receivingItem.managementDate
                        )}
                      </>
                    )}
                  </>
                ),
                body: `분할검수를 진행하겠습니까?`,
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: () => {
                      setConfirmModal(undefined);
                      history.push(
                        `${history.location?.pathname}/multi-location/${receivingItem.id}`
                      );
                    },
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
          />
        );

      const personInCharge =
        rowType === "mainRow" ? (
          ""
        ) : (
          <PersonInCharge
            worker={getPersonInCharge({
              inspecItems: receivingItem.inspectItems,
              getWorkerById,
            })}
            isRemovable={isCurrentManagerReceivingManager}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: "SKU 담당자를 삭제하시겠습니까?",
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: removePersonInCharge({
                      receivingId,
                      itemId: receivingItem.id,
                      resetAfterRemoveInProgressPersonInCharge:
                        resetAfterRemoveInProgressPersonInCharge(counterKey),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // removePersonInCharge={removePersonInCharge({
            //   receivingId,
            //   itemId: receivingItem.id,
            //   resetAfterRemoveInProgressPersonInCharge:
            //     resetAfterRemoveInProgressPersonInCharge(counterKey),
            // })}
          />
        );

      const status =
        rowType === "mainRow" ? "" : completedInspection ? "Y" : "N";

      const reset =
        rowType === "mainRow" ? (
          ""
        ) : (
          <ResetCounting
            skuId={receivingItem.sku.id}
            canReset={canReset}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "content",
                title: (
                  <>
                    {skuId}(SKU ID)
                    {receivingItem.managementDate && (
                      <>
                        <br />
                        {getManagementDateLabel(
                          receivingItem.sku.managementKind,
                          receivingItem.managementDate
                        )}
                      </>
                    )}
                  </>
                ),
                body: (
                  <>
                    초기화를 진행하겠습니까? <br />
                    (분할내역도 초기화 됩니다.)
                  </>
                ),
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: resetInspectionOfItem({
                      receivingId,
                      itemId: receivingItem.id,
                      resetLocalCount: resetLocalCount({
                        hasMultiLocation,
                        inspectItems: receivingItem.inspectItems,
                        skuId: receivingItem.sku.id,
                        itemId: receivingItem.id,
                      }),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // reset={resetInspectionOfItem({
            //   receivingId,
            //   itemId: receivingItem.id,
            //   resetLocalCount: resetLocalCount({
            //     hasMultiLocation,
            //     inspectItems: receivingItem.inspectItems,
            //     skuId: receivingItem.sku.id,
            //   }),
            // })}
          />
        );

      const mappedReceiving: TableDataListItem<ReceivingInspectionDetailTableItem> =
        {
          rowKey,
          selection,
          SKUId,
          count,
          managementDate,
          problem,
          personInCharge,
          status,
          reset,
          completeByForce,
          selectMultiLocation,
          // printBarcode: <PrintBarcode />,
        };

      return mappedReceiving;
    },
    [
      completeInspectionByForce,
      currentManager?.id,
      fetchImageInfoBySKUId,
      getWorkerById,
      handleCountAddByDirectInput,
      handleDirectInputModalOpen,
      history,
      isCurrentManagerReceivingManager,
      itemIdInProgress,
      receivingId,
      removePersonInCharge,
      resetAfterRemoveInProgressPersonInCharge,
      resetInspectionOfItem,
      resetLocalCount,
      setConfirmModal,
      setCountForScanning,
      setItemIdInProgress,
      skuCounting.counter.counterInfo,
      skuCounting.skuInProgress?.inspectingId,
      skuCounting.skuInProgress?.itemId,
    ]
  );

  const tableDataList: TableDataListItem<ReceivingInspectionDetailTableItem>[] =
    useMemo(() => {
      /** 불일치 상품(skuId 가 없는 상품) 필터링 해서 검수 상품을 보여줌. */
      const targetReceivingItems = (receivingItems || []).filter(
        checkForNormalItemAsInspection
      );

      const mapBySKU = convertItemListToMapBySKU(targetReceivingItems);

      if (!mapBySKU) return [];

      return Object.values(mapBySKU).reduce<
        TableDataListItem<ReceivingInspectionDetailTableItem>[]
      >((a, itemList) => {
        if (!itemList || !itemList?.length) return a;

        /**
         * sku를 구성하는 item이 2개 이상인 경우 subRow로 표시한다.
         **/
        const usesSubRow = itemList.length > 1;

        if (!usesSubRow) {
          return [
            ...a,
            ...itemList.map((item) => {
              const hasMultiLocation = getHasMultiLocationInspection(
                item.quantity,
                item.inspectItems
              );

              return createRow({
                rowType: "basicRow",
                receivingItem: item,
                hasMultiLocation,
                currentCount: getCurrentSKUCount({
                  skuId: item.sku.id,
                  counterData: skuCounting.counter.counterInfo,
                  inspectItems: item.inspectItems,
                  hasMultiLocation,
                  itemId: item.id,
                }),
                goalCount: item.quantity,
              });
            }),
          ];
        } else {
          // 관리일자를 사용한다면 mainRow 하위의 subRow로 구분하여 표시한다

          const mainRow: TableDataListItem<ReceivingInspectionDetailTableItem> =
            (() => {
              /**
               * mainRow는 item별 공통 정보를 만들므로 임의의 요소(첫번째요소)를 사용해서 생성할 수 있다.
               */
              const baseItem = itemList[0];

              // mainRow의 count는 subRow들의 총계를 반환
              const currentCount = itemList.reduce(
                (a, c) =>
                  a +
                  getCurrentSKUCount({
                    skuId: c.sku.id,
                    counterData: skuCounting.counter.counterInfo,
                    inspectItems: c.inspectItems,
                    hasMultiLocation: getHasMultiLocationInspection(
                      c.quantity,
                      c.inspectItems
                    ),
                    itemId: c.id,
                  }),
                0
              );

              // mainRow의 count는 subRow들의 총계를 반환
              const goalCount = itemList.reduce((a, c) => a + c.quantity, 0);

              return {
                ...createRow({
                  rowType: "mainRow",
                  receivingItem: baseItem,
                  hasMultiLocation: false,
                  currentCount,
                  goalCount,
                }),
              };
            })();

          mainRow.subRowInfo = (() => {
            return {
              // TODO: 입고 검수 UX가이드에 따라 initialAccordionStatus 추가
              subRowList: itemList.map((v) => {
                const hasMultiLocation = getHasMultiLocationInspection(
                  v.quantity,
                  v.inspectItems
                );

                return createRow({
                  rowType: "subRow",
                  receivingItem: v,
                  hasMultiLocation,
                  currentCount: getCurrentSKUCount({
                    skuId: v.sku.id,
                    counterData: skuCounting.counter.counterInfo,
                    inspectItems: v.inspectItems,
                    hasMultiLocation,
                    itemId: v.id,
                  }),
                  goalCount: v.quantity,
                });
              }),
            };
          })();

          return [...a, mainRow];
        }
      }, []);
    }, [createRow, receivingItems, skuCounting.counter.counterInfo]);

  // function print() {
  //   WebToApp.print();
  // }

  return (
    <>
      <TableForCounting<ReceivingInspectionDetailTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        columnInfo={{
          selection: {
            label: "선택",
            fixedWidth: 32,
          },
          SKUId: {
            label: "SKU ID / 상품바코드",
            fixedWidth: 120,
          },
          count: {
            label: (
              <>
                검수수량/
                <br />
                요청수량(PCS)
              </>
            ),
            fixedWidth: 110,
          },
          managementDate: {
            label: "관리일자",
            fixedWidth: 78,
          },
          problem: {
            label: (
              <>
                비정상상품
                <br />
                (PCS)
              </>
            ),
            fixedWidth: 74,
          },
          personInCharge: {
            label: "담당자",
            fixedWidth: 110,
          },
          status: {
            label: (
              <>
                검품완료
                <br />
                여부
              </>
            ),
            fixedWidth: 58,
          },
          reset: {
            label: "초기화",
            fixedWidth: 74,
          },
          completeByForce: {
            label: (
              <>
                강제처리
                <br />
                (카운트: 0)
              </>
            ),
            fixedWidth: 74,
          },
          selectMultiLocation: {
            label: "분할검수",
            fixedWidth: 74,
          },
        }}
        dataList={tableDataList}
      />

      {toastMessage && (
        <Toast
          uiType="messageWithIcon"
          status="negative"
          message="분할검수 완료 후 입력 가능합니다."
          reset={closeInspectionCompleteToast}
        />
      )}

      {CountForScanningModal}

      {SKUImageInfoModal}
      {ResponseHandlerOfGettingFileURLList}
      {ResponseHandlerOfGettingSKUInfo}

      {ResponseHandlerOfRemovingPersonInCharge}
      {ResponseHandlerOfResetInspectionOfItemExceptInspector}
      {ResponseHandlerOfCompletingInspectionByForce}
    </>
  );
}

export default memo(SKUList);
